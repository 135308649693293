<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg assign-resources"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "manage-users",
              "edit-user",
              "assign-resources-to-user"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="saveResourceAssignment">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>

    <VeeForm v-slot="{ errors }" class="form tiny-input" novalidate>
      <ul v-if="resource && resource.data" class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-pill-link">
            <div class="clebex-item-content-wrapper">
              <span v-if="resource.data.name" class="label larger-label">
                {{ resource.data.name }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item no-bottom-border light">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label
                v-if="resource.data.full_path"
                class="clebex-section-input-label larger-label"
              >
                {{ resource.data.full_path.join(", ") }}
              </label>
            </div>
          </div>
        </li>
        <span class="error-message" v-if="errors['always-booked']">
          {{ errors["always-booked"] }}
        </span>
      </ul>

      <ul class="clebex-item-section overflow-hidden">
        <li
          class="clebex-item-section-item"
          :class="collapse.mode ? 'collapsed' : 'expanded'"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                {{
                  displayLabelName("manage-users", "assign-resources", "mode")
                }}
              </label>
            </div>
            <span
              class="follow-up-icons cursor-pointer"
              @click="
                collapse.mode ? (collapse.mode = false) : (collapse.mode = true)
              "
            >
              <span class="follow-up-icon-item collapsable"
                ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
              /></span>
            </span>
          </div>
        </li>
        <section :class="collapse.mode ? 'collapsed' : 'expanded three'">
          <li class="clebex-item-section-item no-bottom-border light">
            <div
              class="clebex-item-content-wrapper"
              @click="
                () => {
                  if (actionType !== 'edit') {
                    modeSelected = 1;
                  }
                }
              "
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label">{{
                  displayLabelName(
                    "manage-users",
                    "assign-resources",
                    "required-booking"
                  )
                }}</label>
              </div>
              <span v-if="modeSelected === 1" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
          <li class="clebex-item-section-item light">
            <div
              class="clebex-item-content-wrapper"
              @click="
                () => {
                  if (actionType !== 'edit') {
                    modeSelected = 2;
                  }
                }
              "
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label">{{
                  displayLabelName(
                    "manage-users",
                    "assign-resources",
                    "always-booked"
                  )
                }}</label>
              </div>
              <span v-if="modeSelected === 2" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
      </ul>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    v-if="actionType === 'edit'"
                    type="checkbox"
                    id="mode"
                    name="is_active"
                    :checked="parseCheckboxValue()"
                    :value="true"
                    @change="handleChange"
                  />

                  <input
                    v-else
                    type="checkbox"
                    id="mode"
                    name="is_active"
                    :checked="true"
                    :value="true"
                    @change="handleChange"
                  />
                  <label for="mode"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "manage-users",
                    "assign-resources",
                    "permanently-assigned"
                  )
                }}
              </dd>
            </dl>
          </div>
        </li>
      </ul>
      <div class="clebex-item-section">
        <div class="clebex-item-section-item">
          <select-date
            :showDatePicker="dateTimeToExists"
            :disablePreviousDays="true"
            :selectDateFn="date => (dateTo = date)"
            dateFormat="YYYY-MM-DD"
            displayDateFormat="YYYY-MM-DD"
            :disableSelectToday="true"
          />
        </div>
      </div>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "AddAssignableResourcesToUserResourceMode",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDate: defineAsyncComponent(() =>
      import("@/components/global/SelectDate")
    )
  },
  created() {
    this.init();
  },
  data() {
    return {
      submitting: false,
      modeSelected: 2,
      dateTo: false,
      dateTimeToExists: false,
      collapse: {}
    };
  },
  computed: {
    ...mapState("resource", ["resource"]),
    userId() {
      const { user_id } = this.$route.params;
      return user_id;
    },
    resourceId() {
      const { resourceId } = this.$route.params;
      return resourceId;
    },
    assignedResourceId() {
      const { assignedResourceId } = this.$route.params;
      return assignedResourceId;
    },
    ...mapGetters("user", ["resourceAssigned"])
  },
  watch: {
    $route: {
      handler(val) {
        // do only while on this page
        if (val.matched.length === 4) {
          this.init();
        }
      }
    },
    resourceAssigned: {
      deep: true,
      handler(value) {
        if (value) {
          this.modeSelected = value.resource_assignment_type_id;
          if (value.datetime_to) {
            this.dateTo = dayjs(value.datetime_to, "YYYY-MM-DD").format(
              "D MMMM YYYY"
            );
            this.dateTimeToExists = true;
          } else {
            this.dateTo = false;
            this.dateTimeToExists = false;
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("resource", ["getResource"]),
    ...mapActions("user", [
      "postResourceAssignment",
      "putResourceAssignment",
      "getUserResourceAssigned",
      "getUserResourcesAssigned"
    ]),
    init() {
      if (this.actionType === "edit") {
        this.$store.commit("user/setUserResourceAssigned", null, {
          root: true
        });
        this.fetchResourceData();

        this.getUserResourceAssigned({
          userId: this.userId,
          id: this.assignedResourceId
        });
      } else {
        this.fetchResourceData();
      }
    },
    parseCheckboxValue() {
      return (
        this.resourceAssigned && this.resourceAssigned.datetime_to === null
      );
    },
    handleChange(event) {
      this.dateTimeToExists = !event.target.checked;
    },
    fetchResourceData() {
      this.getResource({
        id: this.resourceId,
        params: {
          includes: ["full_path"]
        }
      });
    },
    saveResourceAssignment() {
      if (this.dateTimeToExists && !this.dateTo) {
        return;
      }

      if (this.actionType === "edit") {
        this.putResourceAssignment({
          userId: this.userId,
          id: this.assignedResourceId,
          params: {
            resource_assignment_type_id: this.modeSelected,
            datetime_to: this.dateTimeToExists ? this.dateTo : null
          }
        }).then(response => {
          // fetch assigned resources upon save
          if (response) {
            this.getUserResourcesAssigned({
              id: this.userId,
              params: {
                includes: ["resource_full_path"]
              }
            });
            this.$router.push({
              name: "r_edit-user-add-resources-to-user"
            });
          }
        });
      } else {
        this.postResourceAssignment({
          userId: this.userId,
          params: {
            resource_id: this.resourceId,
            resource_assignment_type_id: this.modeSelected,
            datetime_to: this.dateTimeToExists ? this.dateTo : null
          }
        }).then(response => {
          // fetch assigned resources upon save
          if (response) {
            this.getUserResourcesAssigned({
              id: this.userId,
              params: {
                includes: ["resource_full_path"]
              }
            });
            this.$router.push({
              name: "r_edit-user-add-resources-to-user"
            });
          }
        });
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    actionType: {
      type: String,
      required: false
    }
  },
  beforeUnmount() {
    this.$store.commit("user/setUserResourceAssigned", null, { root: true });
    this.$store.commit("resource/setResourceData", null, { root: true });
  }
};
</script>
